<template>
  <el-card>
    <el-row :gutter="20">
      <el-col :span="4"
        ><el-input
          size="small"
          v-model="queryFilter.keyWorlds"
          placeholder="请输入商品相关信息进行筛选"
          clearable
      /></el-col>
      <el-col :span="4">
        <el-select
          filterable
          @focus="onFocus"
          size="small"
          v-model="queryFilter.supplierId"
          clearable
          placeholder="请选择供应商"
        >
          <el-option
            v-for="item in supplierOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          filterable
          @focus="onFocus"
          size="small"
          v-model="queryFilter.productBrandId"
          clearable
          placeholder="筛选商品品牌"
        >
          <el-option
            v-for="item in brandOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          filterable
          @focus="onFocus"
          size="small"
          v-model="queryFilter.productTypeId"
          clearable
          placeholder="筛选商品类型"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-button size="small" type="primary" @click="flashTable"
        >查询订单</el-button
      >
    </el-row>

    <el-row style="margin-top: 20px">
      <el-table :data="tableData" border stripe row-key="id">
        <el-table-column type="index" fixed="left" label="#"> </el-table-column>
        <el-table-column fixed="left" prop="productNo" width="80" label="货号">
        </el-table-column>
        <el-table-column
          fixed="left"
          width="300"
          prop="productName"
          label="商品名称"
        >
        </el-table-column>
        <el-table-column label="供应商">
          <template slot-scope="scope">
            <el-button type="text" @click="openChangeSupplierDialog(scope.row)">
              {{ scope.row.supplierName }}</el-button
            ></template
          >
        </el-table-column>
        <el-table-column prop="modelName" label="型号"> </el-table-column>
        <el-table-column label="品牌名称">
          <template slot-scope="scope">
            {{ getBrandName(scope.row.productBrandId) }}
          </template>
        </el-table-column>
        <el-table-column label="商品类别">
          <template slot-scope="scope">
            {{ getTypeName(scope.row.productTypeId) }}
          </template>
        </el-table-column>
        <el-table-column width="80" label="单位">
          <template slot-scope="scope">
            {{ scope.row.productUnit + '/' + scope.row.productEnglishUnit }}
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="库存"> </el-table-column>
        <el-table-column prop="salesQuantity" label="销售量"> </el-table-column>
        <el-table-column label="是否在售">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.onSale">上架</el-tag>
            <el-tag type="info" v-else>下架</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>

    <!-- 更改商品供货商信息 -->
    <el-dialog
      title="更改商品供应商"
      :visible.sync="updateProductDialogVisabled"
    >
      <el-form :model="updateSupplerForm" label-width="100">
        <el-form-item prop="name" label="供应商名称">
          <el-select
            filterable
            @focus="onFocus"
            v-model="updateSupplerForm.supplierId"
            placeholder="请选择供应商"
          >
            <el-option
              v-for="item in supplierOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateProductDialogVisabled = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="updataSupplierConfim"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import { getProducts, getAllBrand, getAllType } from '@/api/product'
import { addSupplierProduct, getAll } from '@/api/supplier'
import { iosOnFocus } from '@/utils/iosPlatform.js'
export default {
  data() {
    return {
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        productBrandId: null,
        productTypeId: null,
        supplierId: null,
      },
      tableData: [],
      brandOptions: [],
      typeOptions: [],
      supplierOptions: [],
      updateProductDialogVisabled: false,
      updateSupplerForm: {
        productId: null,
        supplierId: null,
      },
    }
  },
  created() {
    this.getOptions()
    this.getTableData()
    this.getSupplierOptions()
  },
  methods: {
    async getSupplierOptions() {
      let { data } = await getAll()
      this.supplierOptions = data.map((item) => {
        return { id: item.id, name: item.name }
      })
    },
    flashTable() {
      this.queryFilter.pageIndex = 1
      this.getTableData()
    },
    handleSizeChange(val) {
      this.queryFilter.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getTableData()
    },
    async getTableData() {
      let { data } = await getProducts(this.queryFilter)
      this.tableData = data.data
      this.queryFilter.total = data.total
    },
    async getOptions() {
      let { data: brandData } = await getAllBrand()
      this.brandOptions = brandData

      let { data: typeData } = await getAllType()
      this.typeOptions = typeData
    },
    getBrandName(id) {
      try {
        let item = this.brandOptions.filter((i) => {
          return i.value == id
        })
        return item[0]?.label ?? ''
      } catch (error) {
        return ''
      }
    },
    getTypeName(id) {
      try {
        let item = this.typeOptions.filter((i) => {
          return i.value == id
        })
        return item[0]?.label ?? ''
      } catch (error) {
        return ''
      }
    },
    openChangeSupplierDialog(row) {
      this.updateSupplerForm.supplierId = row.supplierId
      this.updateSupplerForm.productId = row.id

      this.updateProductDialogVisabled = true
    },
    async updataSupplierConfim() {
      if (
        !this.updateSupplerForm.productId ||
        !this.updateSupplerForm.supplierId
      ) {
        return this.$message.error('当前上传参数不完整')
      }

      console.log(this.updateSupplerForm)

      await addSupplierProduct(
        this.updateSupplerForm.supplierId,
        this.updateSupplerForm.productId
      )

      this.$message.success('更新商品供货商成功')

      this.getTableData()

      this.updateProductDialogVisabled = false
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
}
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
